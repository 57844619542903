import { AppConfig } from '@/utils/AppConfig';

export const CopyRight = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="mx-6 flex h-full flex-col items-start gap-4 p-5 font-work-sans text-[16px] text-black md:mx-0 lg:flex-row lg:items-center  lg:gap-[5%]">
      <p className="md:min-w-[180px]">
        © {currentYear} {AppConfig.site_name}
      </p>
      <div className="grid w-full grid-cols-2 justify-center gap-4 md:min-w-[400px] lg:grid-cols-4">
        <a href="https://www.artofliving.org/us-en/sms-policy">
          <p className="max-w-fit">SMS Policy</p>
        </a>
        <a href="https://www.artofliving.org/us-en/privacy-policy">
          <p className="max-w-fit">Privacy Policy</p>
        </a>
        <a href="https://www.artofliving.org/us-en/cookie-policy">
          <p className="max-w-fit">Cookie Policy</p>
        </a>
        <a href="https://www.artofliving.org/us-en/terms-of-use">
          <p className="max-w-fit">Terms of Use</p>
        </a>
      </div>
    </div>
  );
};
