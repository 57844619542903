import { Image } from '@nextui-org/react';

interface ListData {
  id: number;
  text: string;
  link?: string;
  target?: string;
}

interface ListStyles {
  container?: string;
  image?: {
    width?: number;
    height?: number;
  };
  list?: string;
  header?: string;
  listItem?: string;
}

interface ListsProps {
  image?: any;
  header?: ListData;
  data: Array<ListData>;
  styles?: ListStyles;
}

export const Lists = ({ image, header, data, styles }: ListsProps) => {
  console.log(header, data, 'header...........');
  return (
    <div className={`${styles?.container} font-work-sans`}>
      {/* Starting Image, if present */}
      {image && (
        <Image
          src={image?.src}
          alt="Meditation Icon"
          width={styles?.image?.width || 44}
          height={styles?.image?.width || 44}
        />
      )}

      {/* List */}
      <ul className={`mt-[14px] flex list-none flex-col ${styles?.list}`}>
        {/* Heading of the List, if present */}
        {header &&
          (header?.link ? (
            <li
              className={`${styles?.header} hover:opacity-75 active:text-black`}
            >
              <a href={header?.link} key={header?.id}>
                {header?.text}
              </a>
            </li>
          ) : (
            <li className={`${styles?.header}`}>{header?.text}</li>
          ))}

        {/* List of items */}
        {data?.map((listItem: ListData) => {
          const isAllCourse = listItem?.text === 'All Courses';
          const helpMeChose = listItem?.text === 'Help me choose';

          return (
            <li
              key={listItem?.id}
              className={`${styles?.listItem} font-normal !text-[#31364e] transition-all hover:!text-primary focus:text-black ${
                isAllCourse
                  ? '!text-[#FF9200] hover:opacity-75'
                  : helpMeChose
                    ? 'inline-block bg-gradient-to-r from-[#3D8BE8] to-[#89BEEC] bg-clip-text !font-medium !text-transparent hover:opacity-75'
                    : ''
              }`}
            >
              <a href={listItem?.link} target={listItem?.target}>
                {listItem?.text}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
