import { Image } from '@nextui-org/react';

export const SocialMedia = () => {
  return (
    <div className="flex w-full justify-center pb-10 pt-6">
      <div className="flex w-[260px] items-center justify-between text-lg sm:gap-2 md:gap-4">
        <a href="https://www.facebook.com/ArtofLivingUSA" target="_blank">
          <Image
            className="rounded-none"
            src="/assets/icons/Fb.svg"
            alt="Facebook Icon"
            width={23}
            height={30}
          />
        </a>
        <a href="https://twitter.com/artoflivingusa" target="_blank">
          <Image
            className="rounded-none"
            src="/assets/icons/Twitter.svg"
            alt="Twitter"
            width={40}
            height={30}
          />
        </a>
        <a href="https://www.instagram.com/artoflivingusa/" target="_blank">
          <Image
            className="rounded-none"
            src="/assets/icons/Insta.svg"
            alt="Instagram Icon"
            width={46}
            height={46}
          />
        </a>
        <a href="https://www.youtube.com/SriSri" target="_blank">
          <Image
            className="rounded-none"
            src="/assets/icons/Youtube.svg"
            alt="Youtube Icon"
            width={50}
            height={34}
          />
        </a>
      </div>
    </div>
  );
};
