import {
  aboutData,
  blogsData,
  coursesData,
  gurudevData,
} from './data/MainFooterData';
import { Lists } from './Lists';

export const MainFooterDesktop = () => {
  const mainFooterStyles = {
    list: 'gap-[6px]',
    header: 'text-lg font-semibold text-black',
    listItem: 'text-base text-[#595858] font-medium',
  };

  return (
    <div className="flex justify-center bg-[#FFF7E4] px-[105px]">
      <div className="my-6 flex w-full justify-between gap-6 font-work-sans">
        <Lists
          header={{ id: 1, text: 'Gurudev', link: '' }}
          data={gurudevData}
          styles={mainFooterStyles}
        />
        <Lists
          header={{ id: 2, text: 'Courses', link: '' }}
          data={coursesData}
          styles={mainFooterStyles}
        />
        <Lists
          header={{ id: 3, text: 'About Us', link: '' }}
          data={aboutData}
          styles={mainFooterStyles}
        />
        <Lists
          header={{ id: 4, text: 'Blogs', link: '' }}
          data={blogsData}
          styles={mainFooterStyles}
        />
      </div>
    </div>
  );
};
