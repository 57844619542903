import { Accordion, AccordionItem } from '@nextui-org/react';
import React from 'react';

import {
  aboutData,
  blogsData,
  coursesData,
  gurudevData,
} from './footer/data/MainFooterData';
import { Lists } from './footer/Lists';

function AccordionFooter() {
  const itemClasses = {
    base: 'py-0 w-full',
    title: 'font-normal text-medium focus:border-none hover:border-none',
    trigger:
      'flex justify-between p-4 focus:outline-none  hover:border-none border-none',
    indicator: 'text-medium',
    content: 'text-small px-2',
  };

  const accordionBodyListStyles = {
    list: 'font-medium text-base text-[#595858] gap-[10px]',
  };

  return (
    <div>
      <Accordion
        className="mt-[40px] flex w-full flex-col gap-1"
        itemClasses={itemClasses}
      >
        <AccordionItem
          className="font-medium text-[#595858]"
          key="gurudev"
          aria-label="gurudev"
          indicator={({ isOpen }) => (
            <div
              className={`text-lg font-medium text-[black] ${
                isOpen && 'rotate-45'
              }`}
            >
              +
            </div>
          )}
          title={<div className="font-medium">Gurudev</div>}
        >
          <div className="px-6">
            <Lists data={gurudevData} styles={accordionBodyListStyles} />
          </div>
        </AccordionItem>
        <AccordionItem
          className="font-medium text-[#595858]"
          key="courses"
          aria-label="courses"
          indicator={({ isOpen }) => (
            <div
              className={`text-lg font-medium text-[black] ${
                isOpen && 'rotate-45'
              }`}
            >
              +
            </div>
          )}
          title={<div className="font-medium">Courses</div>}
        >
          <div className="px-6">
            <Lists data={coursesData} styles={accordionBodyListStyles} />
          </div>
        </AccordionItem>
        <AccordionItem
          className="font-medium text-[#595858]"
          key="about"
          aria-label="about"
          indicator={({ isOpen }) => (
            <div
              className={`text-lg font-medium text-[black] ${
                isOpen && 'rotate-45'
              }`}
            >
              +
            </div>
          )}
          title={<div className="font-medium">About Us</div>}
        >
          <div className="px-6">
            <Lists data={aboutData} styles={accordionBodyListStyles} />
          </div>
        </AccordionItem>
        <AccordionItem
          className="font-medium text-[#595858]"
          key="events"
          aria-label="events"
          indicator={({ isOpen }) => (
            <div
              className={`text-lg font-medium text-[black] ${
                isOpen && 'rotate-45'
              }`}
            >
              +
            </div>
          )}
          title={<div className="font-medium">Blogs</div>}
        >
          <div className="px-6">
            <Lists data={blogsData} styles={accordionBodyListStyles} />
          </div>
        </AccordionItem>
      </Accordion>
    </div>
  );
}

export default AccordionFooter;
