import React from 'react';

import AccordionFooter from './AccordionFooter';
import { CopyRight } from './footer/CopyRight';
import { MainFooterDesktop } from './footer/MainFooterDesktop';
import { SocialMedia } from './footer/SocialMedia';
import Loader from './Loader';
import { useScreenWidth } from './ScreenWidthProvider';

function Footer() {
  const screenWidth = useScreenWidth();
  if (!screenWidth) return <Loader />;
  return (
    <div>
      {screenWidth > 768 ? (
        <div>
          <MainFooterDesktop />

          <div className="flex justify-center px-[105px]">
            <div className=" flex w-full justify-between">
              <div className="w-[75%]">
                <CopyRight />
              </div>
              <div className="mt-6 w-[20%]">
                <SocialMedia />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="w-full bg-[#FFF7E4]">
            <AccordionFooter />
            <SocialMedia />
          </div>
          <CopyRight />
        </div>
      )}
    </div>
  );
}

export default Footer;
