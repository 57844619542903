import type { ReactNode } from 'react';

import Footer from '@/components/Footer';
import Navbar from '@/components/Navbar';

type IMainProps = {
  meta?: ReactNode;
  children: ReactNode;
};

const Main = (props: IMainProps) => (
  <div className="mx-auto max-w-[1440px]  antialiased">
    {props.meta}

    <div className="mx-auto">
      {/* <header>
        <div className="flex h-[68px] justify-between">
          <Navbar />
        </div>
      </header> */}

      <main className="text-xl md:py-5 ">{props.children}</main>

      <footer className="text-sm">
        <Footer />
      </footer>
    </div>
  </div>
);

export { Main };
