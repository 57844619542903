import Head from 'next/head';

type IMetaProps = {
  title: string;
  description: string;
  image: string;
  site_name: string;
};

const Meta = (props: IMetaProps) => {
  return (
    <Head>
      <title>{props.title}</title>
      <meta charSet="UTF-8" key="charset" />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1"
        key="viewport"
      />
      <link
        rel="apple-touch-icon"
        href="/assets/apple-touch-icon.png"
        key="apple"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/assets/favicon-32x32.png"
        key="icon32"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/assets/favicon-16x16.png"
        key="icon16"
      />
      <link rel="icon" href="/assets/favicon.ico" key="favicon" />
      <meta name="title" content={props.title} />
      <meta name="description" content={props.description} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={props.title} />
      <meta property="og:description" content={props.description} />
      <meta property="og:image" content={props.image} />
      <meta property="og:site_name" content={props.site_name} />
    </Head>
  );
};

export { Meta };
