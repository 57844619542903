export const coursesData = [
  {
    id: 1,
    text: 'Art of Living Part 1',
    link: 'https://event.us.artofliving.org/us-en/artoflivingpart1/?utm_source=organic&utm_medium=home&utm_content=menu&course_id=811569',
  },
  {
    id: 2,
    text: 'Sahaj Samadhi Meditation',
    link: 'https://event.us.artofliving.org/us-en/sahajsamadhi/?utm_source=organic&utm_medium=home&utm_content=menu&course_id=999649',
  },
  {
    id: 3,
    text: 'Art of Living Premium',
    link: 'https://event.us.artofliving.org/us-en/premiumcourse/',
  },
  {
    id: 4,
    text: 'Art of Living Part 2',
    link: 'https://event.us.artofliving.org/us-en/artoflivingpart2/?utm_source=organic&utm_medium=home&utm_content=menu&course_id=811570',
  },
  {
    id: 5,
    text: 'Sri Sri Yoga Foundation Program',
    link: 'https://members.us.artofliving.org/us-en/lp/online-foundation-program?utm_source=organic&utm_medium=home&utm_content=menu&course_id=1004431',
  },
  {
    id: 6,
    text: 'All Courses',
    link: 'https://members.us.artofliving.org/us-en/course?utm_source=organic&utm_medium=home&utm_content=allcourses',
  },
  {
    id: 7,
    text: 'Advanced Courses',
    link: 'https://members.us.artofliving.org/us-en/lp/advanced-courses',
  },
  { id: 8, text: 'Healthcare Providers', link: 'https://healingbreaths.org/' },
  {
    id: 9,
    text: 'College Courses',
    link: 'https://www.skycampushappiness.org/',
  },
  {
    id: 10,
    text: 'Destination Retreats',
    link: 'https://artoflivingretreatcenter.org/category/meditation/meditation-mindfulness/?utm_source=organic&utm_medium=home&utm_content=destinationretreats',
  },
];

export const gurudevData = [
  {
    id: 1,
    text: 'About Gurudev',
    link: 'https://www.artofliving.org/us-en/gurudev',
  },
  {
    id: 2,
    text: 'Accolades',
    link: 'https://www.artofliving.org/us-en/awards-and-honours',
  },
  {
    id: 3,
    text: 'Blog',
    link: 'https://www.artofliving.org/us-en/sri-sri-blog',
  },
];

export const aboutData = [
  {
    id: 1,
    text: 'Art of Living',
    link: 'https://www.artofliving.org/us-en/about-us',
    target: '',
  },
  {
    id: 2,
    text: 'Science',
    link: 'https://www.artofliving.org/us-en/meditation/benefits/research-sudarshan-kriya',
    target: '',
  },
  {
    id: 3,
    text: 'Service Projects',
    link: 'https://www.artofliving.org/us-en/service-projects-overview',
    target: '',
  },
  {
    id: 4,
    text: 'Press & Media',
    link: 'https://www.artofliving.org/us-en/press',
    target: '',
  },
  {
    id: 5,
    text: 'Donate',
    link: 'https://members.us.artofliving.org/us-en/lp/donations',
    target: '',
  },
  {
    id: 6,
    text: 'Endowment',
    link: 'https://endowment.us.artofliving.org/',
    target: '',
  },
  {
    id: 7,
    text: 'Bookstore',
    link: 'https://www.artoflivingstore.us/',
    target: '_blank',
  },
  {
    id: 8,
    text: 'Gift Card',
    link: 'https://event.us.artofliving.org/gift-cards/',
    target: '',
  },
  {
    id: 9,
    text: 'Affiliates',
    link: 'https://www.shareasale.com/shareasale.cfm?merchantID=103115',
    target: '',
  },
  {
    id: 10,
    text: 'Contact Us',
    link: 'https://www.artofliving.org/us-en/contact-us',
    target: '',
  },
];

export const blogsData = [
  {
    id: 1,
    text: 'Yoga',
    link: 'https://www.artofliving.org/us-en/yoga',
  },
  {
    id: 2,
    text: 'Meditation',
    link: 'https://www.artofliving.org/us-en/meditation',
  },
  {
    id: 3,
    text: 'Breathing exercises',
    link: 'https://www.artofliving.org/us-en/breathing-exercises',
  },
  {
    id: 4,
    text: 'Wisdom',
    link: 'https://www.artofliving.org/us-en/wisdom',
  },
  {
    id: 5,
    text: 'Spirituality',
    link: 'https://www.artofliving.org/us-en/spirituality',
  },
  {
    id: 6,
    text: 'Lifestyle',
    link: 'https://www.artofliving.org/us-en/lifestyle',
  },
];
